import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import enUS from "../locales/enUS/translation.json";
import jaJP from "../locales/jaJP/translation.json";
import koKR from "../locales/koKR/translation.json";
import zhTW from "../locales/zhTW/translation.json";

const resources = {
  enUS,
  jaJP,
  koKR,
  zhTW,
};

console.log("RESOURCES: ", resources);
i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    partialBundledLanguages: true,
    fallbackLng: "enUS",
    lng: process.env.REACT_APP_LOCALE,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
